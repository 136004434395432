import {Text, H2, LoadingSpinner, Box} from '@amboss/design-system';
import React, {useEffect, useState} from "react";
import axios from "axios";

const Observations = ({observations}) => {

    return (
        <table>
            <thead>
            <tr>
                <th><Text as="span" weight="bold">Observation Code</Text></th>
                <th><Text as="span" weight="bold">Display</Text></th>
                <th><Text as="span" weight="bold">Value</Text></th>
                <th><Text as="span" weight="bold">Effective Date Time</Text></th>
                <th><Text as="span" weight="bold">Issued</Text></th>
            </tr>
            </thead>
            <tbody>
            {observations && observations.filter(e => e !== undefined && e.resource.resourceType === "Observation").map(observation => (
                <tr key={observation.resource.id}>
                    <td><Text as="span">{observation.resource.code.coding[0].code}</Text></td>
                    <td><Text as="span">{observation.resource.code.coding[0].display}</Text></td>
                    <td><Text as="span">
                        {observation.resource.valueString
                            ? observation.resource.valueString
                            : `${observation.resource.valueQuantity.value} ${observation.resource.valueQuantity.unit}`}
                    </Text></td>
                    <td><Text as="span">{observation.resource.effectiveDateTime}</Text></td>
                    <td><Text as="span">{observation.resource.issued}</Text></td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}

const PatientObservations = ({ favorites, patientId }) => {
    const [observations, setObservations] = useState([]);
    const [loadingObservations, setLoadingObservations] = useState(true);
    const [observationsError, setObservationsError] = useState(null);

    useEffect(() => {
        const fetchObservations = async () => {
            try {
                if (favorites && (!favorites.labs || !favorites.labs.length)) {
                    // setObservationsError("No Favorites set.");
                }

                const codes = favorites?.labs.map(lab => lab.code).join(',');
                console.log("Codes: ",codes)

                const params = {
                    count: process.env.REACT_APP_FHIR_ELEMENT_COUNT
                };

                if (codes) {
                    params.code = codes;
                }

                const response = await axios.get(`/api/patient/${patientId ? patientId+"/" : ""}observations${"/laboratory"}`, { params });

                const data = response.data;
                setObservations(data.entry.map(e => e));
                if (!data.entry.length) setObservationsError("No observations found for the selected patient.");

            } catch (error) {
                setObservationsError("Error fetching observations.");
                console.error("Error fetching observations:", error);
            } finally {
                setLoadingObservations(false);
            }
        };

        fetchObservations();
    }, [favorites, patientId]);


    return(
        <Box className={"patient patient-observations"}>
            <div>
                <H2 as="h2" color="primary">Patient Observations</H2>

                {loadingObservations && <LoadingSpinner screenReaderText={"Loading observations"}/>}
                {!loadingObservations && !observationsError && <Observations observations={observations} />}
                {!loadingObservations && observationsError && <Text color="error">{observationsError}</Text>}
            </div>
        </Box>
    )


};

export default PatientObservations;
