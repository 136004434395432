import 'core-js/stable';
import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './styles/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {dark, light, ThemeProvider} from "@amboss/design-system";
import {ViewportProvider} from "./Context/ViewportContext";
import {Provider} from "react-redux";
import {UserProvider} from "./Context/UserContext";
import {store} from "./redux/store";
import {CSRFProvider} from "./Context/CSRFContext";

const Page = () => {
    const [theme, setTheme] = useState(false)

    return(
            <ThemeProvider theme={theme ? dark : light}>
                <Provider store={store}>
                    <UserProvider>
                        <ViewportProvider>
                            <CSRFProvider>
                                <BrowserRouter>
                                    <App theme={theme} setTheme={setTheme} />
                                </BrowserRouter>
                            </CSRFProvider>
                        </ViewportProvider>
                    </UserProvider>
                </Provider>
            </ThemeProvider>

    )
}

const root = document.getElementById('root');
const appRoot = ReactDOM.createRoot(root);

appRoot.render(<Page />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
