import axios from 'axios';

export const fetchTags = async (category, eid) => {
    try {
        const response = await axios.get(`/api/user/favorites/${category}`, { params: { eid } });
        return response.data.tags;
    } catch (error) {
        console.error(`Error fetching ${category} tags:`, error);
    }
};

export const addTag = async (category, eid, tag) => {
    try {
        const response = await axios.post(`/api/user/favorites/${category}`, { eid, tag });
        return response.data.tags;
    } catch (error) {
        console.error(`Error adding ${category} tag:`, error);
    }
};

export const deleteTag = async (category, eid, tag) => {
    try {
        const response = await axios.delete(`/api/user/favorites/${category}`, { data: { eid, tag } });
        return response.data.tags;
    } catch (error) {
        console.error(`Error deleting ${category} tag:`, error);
    }
};
