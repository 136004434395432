import {Text, H2, Box, LoadingSpinner} from '@amboss/design-system';
import React, {useEffect, useState} from "react";
import axios from "axios";

const Conditions = ({conditions}) => {
    return (
        <table>
            <thead>
            <tr>
                <th><Text as="span" weight="bold">Condition Code</Text></th>
                <th><Text as="span" weight="bold">Display</Text></th>
            </tr>
            </thead>
            <tbody>
            {conditions && conditions.filter(e => ( e !== undefined && e.resource.resourceType === "Condition")).map(condition => (
                <tr key={condition.resource.id}>
                    <td><Text as="span">{condition.resource.code.coding[0].code}</Text></td>
                    <td><Text as="span">{condition.resource.code.text}</Text></td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}

const PatientConditions = ({ patientId }) => {
    const [conditions, setConditions] = useState([]);
    const [loadingConditions, setLoadingConditions] = useState(true);
    const [conditionsError, setConditionsError] = useState(null);

    useEffect(() => {
        const fetchConditions = async () => {
            try {

                const params = {
                    count: process.env.REACT_APP_FHIR_ELEMENT_COUNT
                };

                const response = await axios.get(`/api/patient/${patientId ? patientId+"/" : ""}conditions`, { params });

                const data = response.data;
                setConditions(data.entry.map(e => e));
                console.log("Found conditions: ", data.entry.length)
                if (!data.entry.length) setConditionsError("No conditions found for the selected patient.");

            } catch (error) {
                setConditionsError("Error fetching conditions.");
                console.error("Error fetching conditions:", error);
            } finally {
                setLoadingConditions(false);
            }
        };

        fetchConditions();

    }, [patientId])

    console.log(conditions)

    return (
        <Box className={"patient patient-conditions"}>
            <div>
                <H2 as="h2" color="primary">Patient Conditions</H2>

                {loadingConditions && <LoadingSpinner screenReaderText={"Loading conditions"}/>}
                {!loadingConditions && !conditionsError && <Conditions conditions={conditions} />}
                {!loadingConditions && conditionsError && <Text color="error">{conditionsError}</Text>}
            </div>
        </Box>
    );
};

export default PatientConditions;
