// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist/es/constants';

import userReducer from './slices/userSlice';
import patientReducer from './slices/patientSlice';
import searchReducer from './slices/searchSlice';

const userPersistConfig = {
    key: "user",
    storage,
};

const persistedUserReducer = persistReducer(
    userPersistConfig,
    userReducer
);

const rootReducer = combineReducers({
    user: persistedUserReducer,
    patient: patientReducer,
    search: searchReducer
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["user"],
    blacklist: ["patient"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

const persistor = persistStore(store);

export { store, persistor };
