import {Box, Button} from "@amboss/design-system";
import React from "react";

export default function OAuth2Button () {

    return(
        <Box>
            {/* New button as requested */}
            <Button
                as={"a"}
                href={"/api/oauth/launch"}
                leftIcon={"key"}
                onBlur={function noRefCheck(){}}
                onClick={function noRefCheck(){}}
                onFocus={function noRefCheck(){}}
                type="button"
                variant="primary"
            >
                Launch my EHR context
            </Button>
        </Box>
    )
}
