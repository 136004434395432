import React, { createContext, useContext, useState, useEffect } from 'react';

const ViewportContext = createContext();

export const ViewportProvider = ({ children }) => {
    const [viewport, setViewport] = useState(3); // default to large

    useEffect(() => {
        const setViewportSize = () => {
            let newSize = 4;
            if (window.innerWidth < 576) {
                newSize = 0;
            } else if (window.innerWidth < 768) {
                newSize = 1;
            } else if (window.innerWidth < 992) {
                newSize = 2;
            } else if (window.innerWidth < 1280) {
                newSize = 3;
            }

            if (newSize !== viewport) {
                setViewport(newSize);
            }
        };

        setViewportSize();
        window.addEventListener('resize', setViewportSize);

        return () => {
            window.removeEventListener('resize', setViewportSize);
        };
    }, [viewport]);

    return (
        <ViewportContext.Provider value={viewport}>
            {children}
        </ViewportContext.Provider>
    );
}

export const useViewport = () => useContext(ViewportContext);
