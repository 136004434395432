import React, {useCallback, useEffect, useState} from 'react';
import { DropdownMenu } from "@amboss/design-system";
import { useNavigationItems } from '../../hooks/useNavigationItems';
import { useNavigate } from 'react-router-dom';
import {useViewport} from "../../Context/ViewportContext";

function NavMenu({ theme, setTheme, loggedIn }) {
    const tabs = useNavigationItems();
    const viewport = useViewport();
    const navigate = useNavigate();

    const [menuItems, setMenuItems] = useState([]);
    const [navItems, setNavItems] = useState([]);
    const [themeItems, setThemeItems] = useState([]);

    useEffect(() => {
        setMenuItems([ ...themeItems, ...navItems])
    }, [navItems, themeItems])

    // Effect for building the menu items based on tabs
    useEffect(() => {
        const newMenuItems = [];
        console.log("Effect 1")
        console.log(tabs)

        // Add navigation items to dropdown menu
        if(tabs?.length) {
            tabs.forEach(tab => {
                newMenuItems.push({
                    icon: tab.icon,
                    label: tab.label,
                    onSelect: () => {
                        if (!tab.disabled) navigate(`/${tab.label.toLowerCase()}`);
                    }
                });
            });
        }

        setNavItems(newMenuItems)
    }, [navigate, tabs]);

    const bulbTheme = useCallback(() => {
        return ([
            {
                icon: 'bulb',
                label: `${theme ? 'Lighter' : 'Darker'}`,
                onSelect: () => setTheme(!theme)
            },
            'separator'
        ])
    }, [theme, setTheme])

    // Effect for the theme change based on viewport
    useEffect(() => {
        if (viewport < 3) {
            setThemeItems(bulbTheme);
        } else {
            setThemeItems([])
        }
    }, [viewport, bulbTheme]);



    return (
        <DropdownMenu
            iconName="menu"
            className="menu"
            menuItems={menuItems}
            onMenuClose={function noRefCheck(){}}
            onMenuOpen={function noRefCheck(){}}
        />
    );
}

export default NavMenu;
