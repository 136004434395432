import {Box, Button, Container, Divider, FormFieldGroup, Text, Toggle} from "@amboss/design-system";
import React, {useState} from "react";
import FavoritesSection from "./UserModalFavorites";

export default function UserModalContainer ({theme, setTheme, setLogoutDialog}) {
    const [activeTime] = useState("00:00:00");

    return (
        <Container>
            {/* Display Section */}
            <Box>
                <Text size="m" weight="bold">Display</Text>
                <FormFieldGroup>
                    <Toggle
                        label={theme ? "Dark Theme" : "Light Theme"}
                        name="theme"
                        checked={theme}
                        onChange={() => setTheme(!theme)}
                    />
                </FormFieldGroup>
            </Box>
            <Divider />

            {/* Favorites Section */}
            <Box>
                <Text size="m" weight="bold">Favorites</Text>
                <FavoritesSection />
            </Box>
            <Divider />

            {/* Timer */}
            <Box>
                <Text size="m" weight="bold">Active Time</Text>
                <Text as={"p"}>{activeTime}</Text>
            </Box>
            <Divider />

            {/* Logout */}
            <Box>
                <Button
                    as="button"
                    type="button"
                    variant="primary"
                    className="button--destructive"
                    onClick={() => {setLogoutDialog(true)}}
                >Logout</Button>
            </Box>
        </Container>
    )
}
