import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

// Async thunk for fetching patient data
export const fetchPatient = createAsyncThunk(
    'patient/fetchPatient',
    async (patientId, { getState, dispatch }) => {
        try {
            const id = patientId;
            console.log("Running redux patient: ", patientId)
            const response = await fetch(`/api/patient${id ? "/"+id : ""}`);
            if (!response.ok) throw new Error('Failed to fetch patient data');

            return await response.json();

        } catch (error) {
            console.error('Failed fetching patient data:', error);
            throw error;
        }
    }
);


export const patientSlice = createSlice({
    name: 'patient',
    initialState: {
        currentPatient: null,
        status: 'idle',
        error: null
    },
    reducers: {
        clearPatientData: (state) => {
            state.currentPatient = null;
            state.status = 'idle';
            state.error = null;
        },
        setCurrentPatient: (state, action) => {
            state.currentPatient = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPatient.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPatient.fulfilled, (state, action) => {
                /*
                 * Formerly adopted to store array of patients when allowed.
                 */

                /*
                 if (action.payload && Array.isArray(action.payload.entry)) {
                    state.patients = action.payload.entry;
                 }
                 */
                if (action.payload) {
                    // state.patients = [action.payload];
                    state.currentPatient = action.payload;
                } else {
                    console.error('Unexpected payload:', action.payload);
                }
                state.status = 'succeeded';
            })

            .addCase(fetchPatient.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const selectPatientStatus = (state) => state.patient.status;

export const selectCurrentPatientId = (state) => state.patient?.currentPatient?.resource?.id || state.patient?.currentPatient?.id;
export const selectCurrentPatientName = (state) => state.patient?.currentPatient?.resource?.name[0]?.family || state.patient?.currentPatient?.name[0]?.family;
export const selectCurrentPatientFirstName = (state) => state.patient?.currentPatient?.resource?.name[0]?.given.join(' ') || state.patient?.currentPatient?.name[0]?.given?.join(' ');

export const { clearPatientData, setCurrentPatient } = patientSlice.actions;
export default patientSlice.reducer;
