import React, { useState } from "react";
import {
    Modal,
} from "@amboss/design-system";
import UserModalContainer from "./UserModalContainer";
import UserModalLogout from "./UserModalLogout";

const UserModal = ({open, setUserModalOpen, theme, setTheme}) => {
    const [hasLogoutDialog, setLogoutDialog] = useState(false)

    return (
        <Modal
            header="User Settings"
            actionButton={!hasLogoutDialog && {
                text: 'Okay',
                variant: 'primary',
                type: 'submit'
            }}


            onAction={(action) => {
                if (action === "cancel" && open === true) {
                    setUserModalOpen(false);
                } else if (action === "action") {
                    setUserModalOpen(false);
                }
                if (open === false) open = true;
            }}
            cancelButtonLabel={!hasLogoutDialog && "Cancel" }
            privateProps={{ skipPortal: false }}
            role="dialog"
        >
            <div style={{ display: hasLogoutDialog ? 'none' : 'block' }}>
                <UserModalContainer theme={theme} setTheme={setTheme} hasLogoutDialog={hasLogoutDialog} setLogoutDialog={setLogoutDialog}/>
            </div>

            <div style={{ display: hasLogoutDialog ? 'block' : 'none' }}>
                <UserModalLogout hasLogoutDialog={hasLogoutDialog} setUserModalOpen={setUserModalOpen} setLogoutDialog={setLogoutDialog}/>
            </div>
        </Modal>
    );
};

export default UserModal;
