import {Button, H6, RoundButton} from "@amboss/design-system";
import UserModal from "./UserModal/UserModal";
import LoginModal from "./LoginModal/LoginModal";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentUserFirstName, selectCurrentUserLastName} from "../../redux/slices/userSlice";
import {useUser} from "../../Context/UserContext";

export default function UserMenuAvatar({ loggedIn, theme, setTheme }) {
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isUserModalOpen, setUserModalOpen] = useState(false);
    const currentUserFirstName = useSelector(selectCurrentUserFirstName);
    const currentUserLastName = useSelector(selectCurrentUserLastName);
    const { forceUserCheck } = useUser();
    const wasUserModalOpen = useRef(false);

    useEffect(() => {
        if (wasUserModalOpen.current && !isUserModalOpen) {
            forceUserCheck();
        }
        wasUserModalOpen.current = isUserModalOpen;
    }, [isUserModalOpen, forceUserCheck]);


    return (
        loggedIn ?
            <>
                <Button
                    className={"button__user-avatar"}
                    leftIcon={"users"}
                    as={"button"}
                    type={"button"}
                    variant={"tertiary"}
                    onClick={() => {
                        setUserModalOpen(true)
                    }}
                    size={"l"}
                ><H6>{currentUserFirstName?.slice(0,1)}{currentUserLastName?.slice(0,1)}</H6></Button>
                { isUserModalOpen &&
                    <UserModal open={false} setUserModalOpen={setUserModalOpen}
                               isUserModalOpen={isUserModalOpen} theme={theme} setTheme={setTheme} />
                }
            </>
            :
            <>
                <RoundButton
                    icon={"users"}
                    type={"button"}
                    onClick={() => {
                        setLoginModalOpen(true)
                    }}
                />
                {isLoginModalOpen &&
                    <LoginModal open={false} setLoginModalOpen={setLoginModalOpen}
                                isLoginModalOpen={isLoginModalOpen}/>
                }
            </>

    );
}
