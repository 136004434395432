import React, {useEffect, useRef, useState} from 'react';

import {
    Box, Container, Inline,
    PictogramButton,
} from "@amboss/design-system";
import CustomLogo from "./Logos/CustomLogo";
import {useViewport} from "../../Context/ViewportContext";
import NavMenu from "./NavMenu";

import {useUser} from "../../Context/UserContext";
import EpicLogo from "./Logos/EpicLogo";
import { Link } from '@amboss/design-system';
import {Link as RouterLink} from "react-router-dom"
import UserMenuAvatar from "./UserMenuAvatar";
import SearchWrapper from "./HeaderSearch/SearchWrapper";

function Header({ theme, setTheme, hasSearchBar, showSearchBar }) {

    const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);

    const {loggedIn} = useUser()
    const viewport = useViewport();

    const searchBarWrapper = useRef();

    const handleClickOutside = (e) => {
        e.stopPropagation();
        console.log("clicked", e.target)
        if (searchBarWrapper.current && searchBarWrapper.current.contains(e.target)) {
            setShowSearchSuggestions(true);
        } else setShowSearchSuggestions( false )
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            setShowSearchSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    return (
        <Container elevation={2} className={"header-wrapper__container"}>
            <Box vSpace={"s"} className={"header-wrapper__box"}>
                <Inline alignItems="spaceBetween" vAlignItems="center" className={"header-wrapper__inline"}>
                    <UserMenuAvatar theme={theme} setTheme={setTheme} loggedIn={loggedIn} />
                    <Inline vAlignItems="center">
                        { hasSearchBar ?
                            <SearchWrapper
                                searchBarWrapper={searchBarWrapper}
                                showSearchSuggestions={showSearchSuggestions}
                                setShowSearchSuggestions={setShowSearchSuggestions}
                            />
                        :
                        <>
                            <Link className={"ehr-tagline"} as={RouterLink} to="/">
                                <Inline vAlignItems={"center"} space={["xs","s","m"]}>
                                    {viewport > 1 && <EpicLogo />}
                                    <CustomLogo />
                                </Inline>
                            </Link>
                        </>
                        }
                    </Inline>


                    <Inline vAlignItems="center">
                        <>{ !(hasSearchBar && viewport < 2) &&
                            <PictogramButton
                                icon="search"
                                onBlur={function noRefCheck(){}}
                                onClick={() => showSearchBar(true)}
                                onFocus={function noRefCheck(){}}
                                size="s"
                                type="button"
                                variant="tertiary"
                            />
                        }</>
                        <NavMenu theme={theme} setTheme={setTheme} loggedIn={loggedIn} />
                    </Inline>
                </Inline>
            </Box>
        </Container>
    )
}

export default Header;
