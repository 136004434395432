import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, CardBox, Divider, Inline, LoadingSpinner, Stack, Text} from "@amboss/design-system";
import { useSelector } from 'react-redux';
import {useCSRF} from "../../../Context/CSRFContext";
import fetchRecommendedSearchQueries from "./fetchRecommendedSearchQueries";
import handleSearchQueryDeletion from "./handleSearchQueryDeletion";

function SearchSuggestions({  searchTerm, setSearchTerm, handleSearchSubmit }) {

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [recommendedQueries, setRecommendedQueries] = useState([]);
    const recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];

    const [hasLoadedQueries, setHasLoadedQueries] = useState(false);
    const [isLoadingQueries, setLoadingQueries] = useState(false)
    const limitedRecentSearches = recentSearches.slice(0, 4);

    const currentUser = useSelector(state => state.user?.currentUser);
    const currentPatient = useSelector(state => state.patient?.currentPatient);
    const currentPatientConditions = useSelector(state => state.condition?.data?.entry);
    const currentPatientObservations = useSelector(state => state.observation?.data?.entry);

    const menuItems = [{
        label: 'Click me not',
        leftIcon: 'watch',
        onSelect: function noRefCheck(){}
    }]
    const searchSuggestionPrompt = (user, patient, conditions, observations) => {
        return `Given a ${patient?.age || ""} year old ${patient?.ethnicity || ""} patient with conditions: ${conditions?.map(cond => cond.resource.code?.text).join(', ') || "none"}, what might be the top three questions a ${user?.speciality || "physician"} with ${new Date().getFullYear() - user?.graduationYear || 3} years of experience would want to research in a medical encyclopedia regarding the treatment or diagnosis of the patient?`;
    };

    const {csrfToken} = useCSRF();

    useEffect(() => {
        console.log("hasLoadedQueries: ",hasLoadedQueries)
        console.log("recommendedQueries: ",recommendedQueries)
        if (!hasLoadedQueries) {
            if(!isLoadingQueries) {
                setLoadingQueries(true)
                const prompt = searchSuggestionPrompt(currentUser, currentPatient, currentPatientConditions, currentPatientObservations);
                fetchRecommendedSearchQueries({prompt, currentUser, currentPatient, setRecommendedQueries, csrfToken, isMounted})
                    .then((res) => console.log(res))
                    .catch((err) => console.error("error loading: ", err.message))
            }
            if(recommendedQueries.length) {
                setLoadingQueries(false)
                setHasLoadedQueries(true);
            }
        } else if (!recommendedQueries.length) setHasLoadedQueries(false)
    }, [currentUser, currentPatient, currentPatientConditions, currentPatientObservations, hasLoadedQueries, recommendedQueries, setRecommendedQueries, csrfToken, isLoadingQueries]);

    return (
        <div className={"search__suggestions--container"} >
            <Card
                dropdown={{menuItems}}
                overflow="visible"
                title="What are you looking for?"
            >
                <CardBox>
                    <Stack space={"m"}>
                    <>
                        {recommendedQueries.length < 1 && isLoadingQueries && <LoadingSpinner screenReaderText={"Loading query suggestions"} size={30} /> }
                        {recommendedQueries.length < 1 && hasLoadedQueries && !isLoadingQueries && <Text>Something went wrong. Try again later ...</Text>}
                        {recommendedQueries.length < 1 && hasLoadedQueries && !isLoadingQueries && <Text>Something went wrong. Try again later ...</Text>}
                    </>
                    {recommendedQueries.slice(0,3).map(query => (
                        <Inline space={"xxs"} vAlignItems={"center"} alignItems={"spaceBetween"}>
                            <Button className={"search-bar__suggestion--text"} as={"text"}
                                    onClick={() => {
                                        setSearchTerm(query.text);
                                        handleSearchSubmit()
                                    }} key={query.id} variant="tertiary" size="s" leftIcon={"zap"}
                            >
                                {query.text.length >50 ? query.text.slice(0, 50) + "..." : query.text}
                            </Button>
                            <Button as="button" leftIcon="x-circle-filled"
                                    onClick={() => handleSearchQueryDeletion({queryId:query.id, queryText:query.text, currentPatient, currentUser, csrfToken, recommendedQueries, setRecommendedQueries})}
                                    onFocus={function noRefCheck(){}} size="s" type="button" variant="tertiary" destructive
                            />
                        </Inline>
                    ))}
                </Stack>
                </CardBox>
                <Divider />
                <CardBox>
                    <Stack space={"m"}>
                        {limitedRecentSearches.map(search => (
                            <Button className={"search-bar__suggestion--text"} as={"text"} onClick={function noRefCheck() { }} key={search} variant={"tertiary"} size={"s"} leftIcon={"timer-on"}>
                                {search.length > 30 ? search.slice(0, 30) + "..." : search}
                            </Button>
                        ))}
                    </Stack>
                </CardBox>
            </Card>
        </div>
    );
}

export default SearchSuggestions;
