import {Box, Callout, FormFieldGroup, Inline, Input, PasswordInput, Stack} from "@amboss/design-system";
import {useCallback, useEffect, useState} from "react";
import {useCSRF} from "../../../Context/CSRFContext";
import {Link} from "react-router-dom";
import {useUser} from "../../../Context/UserContext";

export default function LoginModalForm ({onLogin, setError, error, modalActionType, setIsLoading, attempts, setAttempts}) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const {csrfToken} = useCSRF();
    const {forceUserCheck} = useUser();

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email); // returns true if xy@mail.fr
    };

    const isValidForm = useCallback(() => {
        return isValidEmail(email) && password;
    }, [email, password]);

    useEffect(() => {
        if(modalActionType === 'cancel') {
            setPassword('');
        }
    }, [modalActionType]);

    useEffect(() => {
        setError(!isValidForm());
    }, [email, password, error, setError, isValidForm]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch('/api/auth/medicuja/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ email, password, _csrf: csrfToken }),
            });

            const data = await response.json();

            // pass login handler logic to LoginModal
            // handle errors and track attempts
            if (data.success) {
                console.log("Logged in successfully.")
                forceUserCheck();
                onLogin(true, "Logged in successfully.");
                setAttempts(0) // reset failed login attempts
                setError('')
            } else {
                setAttempts(prev => prev + 1);
                setError('Invalid login credentials.');
                onLogin(false, 'Invalid login credentials.');
            }
        } catch (err) {
            setAttempts(prev => prev + 1);
            onLogin(false, 'Something went wrong. Please try again.');
        } finally {
            setIsLoading(false)
        }
    };

    return (
        <Box space={["xs", "s", "s"]} role="form" alignText="left">
            <Stack alignItems="left" space="xxs">
                <form className="login-modal-form" name="amboss-login" onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit(e)
                }}>
                    <Inline vSpace={["m", "s", "m"]}>
                        <FormFieldGroup
                            className="login-modal-form__inputgroup--email"
                            label="Email"
                        >
                            <Input
                                className="login-modal-form__input--email"
                                name="email"
                                tabIndex={0}
                                value={email}
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                hasError={!isValidEmail(email)}
                                aria-invalid={!isValidEmail(email)}
                                aria-describedby={!isValidEmail(email) ? 'email-error' : null}
                            />
                        </FormFieldGroup>
                        <FormFieldGroup
                            className="login-modal-form__inputgroup--password"
                            label="Password"
                        >
                            <PasswordInput
                                className="login-modal-form__input--password"
                                name="password"
                                tabIndex={0}
                                value={password}
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                hasError={!password}
                                aria-invalid={!password}
                                aria-describedby={!password ? 'password-error' : null}
                                iconAriaLabel="password"
                            />
                        </FormFieldGroup>
                        <Inline><Stack className={"login-modal-form__error-stack"} id={"loginModalForm"}>
                            { ( attempts > 0 && attempts < 2 ) &&
                                <Callout text="Invalid login credentials. Please try again." icon="x-circle-filled" type="error" className={"login-modal-form__error-stack--error"} />
                            }
                            { ( attempts > 1 && attempts < 4 ) &&
                                <Callout text="Forgot your password?" description={<Link href="https://next.amboss.com/us/forgotpassword">Click here to reset it</Link>} icon={"info"} type={"info"} className={"login-modal-form__error-stack--info"}/>
                            }
                            {attempts > 4 &&
                                <Callout
                                    text="Trouble signing in?"
                                    description={
                                        <>
                                            Feel free to <Link href="https://support.amboss.com/hc/en-us/articles/4405038645140-Contacting-AMBOSS-Support">contact our support</Link>!
                                        </>
                                    }
                                    icon="info"
                                    type="info"
                                    className={"login-modal-form__error-stack--error"}
                                />
                            }
                        </Stack></Inline>
                        <button type="submit" style={{display: 'none'}} aria-hidden="true"
                                aria-describedby="button"></button>
                    </Inline>
                </form>
            </Stack>
        </Box>
    )
}
