import React from 'react';
import { useSelector } from 'react-redux';
import { Column } from "@amboss/design-system";
import PatientObservations from "./PatientObservations";
import PatientConditions from "./PatientConditions";
import {selectCurrentPatientId} from "../../../redux/slices/patientSlice";

function Patient() {

    const favorites = useSelector(state => state.user.currentUser.favorites);
    const patientId = useSelector(selectCurrentPatientId);

    return (
        <Column>
            <PatientObservations patientId={patientId} favorites={favorites} />
            <PatientConditions patientId={patientId} />
        </Column>
    );
}

export default Patient;
