import React, { useEffect, useState } from 'react';
import {
    Box, Columns, LoadingSpinner, Text
} from "@amboss/design-system";
import { useDispatch, useSelector } from "react-redux";
import {storeSearchResults, setLoading, setError, selectIsLoadingSearch} from "../../../redux/slices/searchSlice";
import {selectCurrentPatientId} from "../../../redux/slices/patientSlice";
import SearchResultItem from "./SearchResultItem";
import {stripHTML} from "../../../utils/oneLinerFunctions";
import axios from "axios";

function Search() {
    const dispatch = useDispatch();
    const patientId = useSelector(selectCurrentPatientId);
    const searchResults = useSelector(state => state.search?.results);
    const isLoadingSearch = useSelector(selectIsLoadingSearch);

    const [abstracts, setAbstracts] = useState([]);

    const [conditions, setConditions] = useState([]);
    const [loadingConditions, setLoadingConditions] = useState(true);
    const [conditionsError, setConditionsError] = useState(null);

    useEffect(() => {
        const fetchConditions = async () => {
            try {
                const params = {
                    count: process.env.REACT_APP_FHIR_ELEMENT_COUNT
                };

                const response = await axios.get(`/api/patient/${patientId ? patientId+"/" : ""}conditions`, { params });

                const data = response.data;
                setConditions(data.entry.filter(e => e !== undefined && e.resource.resourceType === "Condition").map(e => e));

                console.log("Found conditions: ", conditions)
                if (!data.entry.length) setConditionsError("No conditions found for the selected patient.");

            } catch (error) {
                setConditionsError("Error fetching conditions.");
                console.error("Error fetching conditions:", error);
            } finally {
                setLoadingConditions(false);
            }
        };

        if (!patientId) {
            return; // exit early if no patientId
        }

        fetchConditions();
    }, [patientId])


    useEffect(() => {
        async function fetchData() {
            if( !loadingConditions && ( !conditions || !conditions?.length || conditionsError)) {
                dispatch(setError("No conditions found"));
                dispatch(setLoading(false))
                return ;
            } else if ( loadingConditions && !conditionsError ) {
                dispatch(setLoading(true));
                return ;
            }

            const terms = conditions.map(e => e.resource.code.text)
            console.log("Terms:",terms)

            try {
                const response = await fetch(`/api/search/amboss?media=true&articles=true`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({terms})
                });
                const data = await response.json();

                if (data.searchSuggestionInstantResults) {
                    const fetchedAbstracts = data.searchSuggestionInstantResults.map(result => result.metaData?.abstract);
                    setAbstracts(fetchedAbstracts);

                    data.searchSuggestionInstantResults.forEach(result => {
                        if (result.metaData) {
                            delete result.metaData.abstract;
                        }
                    });
                }
                dispatch(storeSearchResults(data));
            } catch (error) {
                dispatch(setError(error.message));
            } finally {
                dispatch(setLoading(false));
            }
        }

        if (!patientId || loadingConditions || conditionsError || !conditions?.length) {
            return; // exit early based on conditions
        }

        fetchData();
    }, [patientId, dispatch, loadingConditions]);

    return (
        <Box space="m">
            { isLoadingSearch && <Box tSpace={"xs"} bSpace={"l"}> <LoadingSpinner screenReaderText={"Loading Search results"} size={searchResults ? 20 : 120} /> </Box>}
            { !isLoadingSearch && ( !searchResults || searchResults.statusCode ) && <Box tSpace={"xs"} bSpace={"l"}> <Text as={"p"}>No relevant AMBOSS articles have been found. You can try the search bar ...</Text> </Box>}
            <Columns gap={"xl"}>
                {searchResults?.searchSuggestionInstantResults?.filter(result => result.articleEid).map((result, index) => (
                    <SearchResultItem result={result} abstract={stripHTML(abstracts[index])} />
                ))}

            </Columns></Box>
    )
}

export default Search;
