import React from "react";
import {useViewport} from "../../../Context/ViewportContext";
import { ReactComponent as EpicLogoSVG } from "../../../assets/epic-logo.svg";

function EpicLogo() {
    // Handle window resize
    const viewport = useViewport();

    return viewport < 3 ? (
        <EpicLogoSVG width="25" height="24" />
    ) : (
        <EpicLogoSVG width="50" height="40" />
    );
}

export default EpicLogo;
