import React, { useEffect, useState } from 'react';
import {Button, Divider, Inline, Select, Stack, Tag} from "@amboss/design-system";
import { useSelector } from "react-redux";
import { selectCurrentUserEid } from "../../../redux/slices/userSlice";
import { addTag, deleteTag, fetchTags } from "../../../utils/favoriteTagFunctions";

const FavoriteTags = ({ category }) => {
    const availableTags = {
        labs: [
            { name: 'Ca2+', code: '200' },
            { name: "CRP", code: '1511115' },
            { name: "Pt+", code: '15111' }],
        meds: [
            { name: 'Penicillin i.v. 500mg', code: '0' },
            { name: "Ibuprofen p.o. 400mg", code: '1' },
            { name: "Acetaminophen p.o. 500mg", code: '2' }],
        scores: [
            { name: 'GCS', code: '0'},
            { name: 'Child-Pugh-Score', code: '1' },
            { name: 'CHADSVASC', code: '2' },
            { name: "HAS-BLED", code: '3' }]
    }
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState({});
    const currentUserEid = useSelector(selectCurrentUserEid);

    useEffect(() => {
        const loadTags = async () => {
            const fetchedTags = await fetchTags(category, currentUserEid);
            setTags(fetchedTags);
        };

        loadTags();
    }, [currentUserEid, category]);

    const handleTagChange = (event) => {
        setSelectedTag({name: event.target.innerText, code: event.target.value});
    };

    const handleAddTag = async () => {
        if (selectedTag && !tags?.some(t => t.name === selectedTag.name)) {
            const updatedTags = await addTag(category, currentUserEid, selectedTag);
            setTags(updatedTags);
        }
    };

    const handleDeleteTag = async (tagToDelete) => {
        const updatedTags = await deleteTag(category, currentUserEid, tagToDelete);
        setTags(updatedTags);
    };

    return (
        <Stack spacing="medium">
            <Inline vAlignItems={"bottom"}>
                <Select
                    maxHeight={130}
                    label={`Favorite ${category}`}
                    options={availableTags[category]?.filter(e => !tags?.some(t => t.name === e.name)).map(tag => ({ label: tag.name, alias: tag.code, value: tag.code }))}
                    value={selectedTag.code}
                    onChange={handleTagChange}
                    placeholder={`Add a ${category} tag`}
                    name={`favourite-${category}-selector`}
                    emptyStateMessage={"Nothing left"}
                />
                <Button onClick={handleAddTag} variant="primary" size="m">
                    Add
                </Button>
            </Inline>
            <Stack>
                <Inline vAlignItems="center" gap="small">
                {tags?.map(tag => (
                        <Tag key={tag.name} label={tag.name} isRemovable onClear={() => handleDeleteTag(tag)} />
                ))}
                </Inline>
            </Stack>
            <Divider />
        </Stack>
    );
};

const FavoritesSection = () => {
    const categories = ['labs', 'meds', 'scores'];

    return (
        <div>
            {categories.map(category => (
                <FavoriteTags key={category} category={category} />
            ))}
        </div>
    );
};

export default FavoritesSection;
