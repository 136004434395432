import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Container,
} from "@amboss/design-system"
import './styles/css/index.css';
import './styles/css/styles.css';
import {Route, Routes} from "react-router-dom";
import OAuth2Button from "./components/Core/OAuth2Button";
import Header from "./components/Header/Header";
import PatientCallout from "./components/Body/PatientCallout/PatientCallout";
import Patient from "./components/Body/Patient/Patient";
import {useDispatch, useSelector} from "react-redux";
import {fetchPatient, selectPatientStatus} from "./redux/slices/patientSlice";
import Search from "./components/Body/Search/Search";

function App(props) {
    const {theme, setTheme} = props;
    const [hasSearchBar, showSearchBar] = useState(false)

    // Fetch patient resource
    const dispatch = useDispatch();
    const patientStatus = useSelector(selectPatientStatus);
    const patientData = useSelector(state => state.patient?.currentPatient)

    useEffect(() => {
        if (patientStatus === 'idle') {
            dispatch(fetchPatient(process.env.NODE_ENV === "development" ? 1 : ""));
        }
    }, [patientStatus, dispatch]);

    const headerRef = useRef(null);

    return (
      <Container elevation={0}>
          <Box space={["s","m","xl"]} bSpace={["xxs","m","m"]}>
              <Header
                  ref={headerRef}
                  theme={theme}
                  setTheme={setTheme}
                  hasSearchBar={hasSearchBar}
                  showSearchBar={showSearchBar}
              />
          </Box>
          <Box space={["s","m","xl"]} bSpace={["xxs","m","m"]}>
              <PatientCallout
                  patientStatus={patientStatus}
                  patient={patientData}
              />
          </Box>
          <Box>
              <div className="amboss-ds-portal" style={{zIndex: 100, position: "absolute"}} />
              <Routes>
                  <Route path="/search" element={<Search />} />
                  <Route path="/patient" element={<Patient />} />
                  <Route path="/" element={<OAuth2Button />} />
              </Routes>
          </Box>
        </Container>
    );
}

export default App;
