
import React from 'react';
import { Callout} from "@amboss/design-system";

function PatientCallout ({ patientStatus, patient }) {

    const patientInfoContent = () => {
        console.log("Callout:",patientStatus)
        if (patientStatus === 'loading') {
            return "Loading …";
        } else if (patientStatus === 'succeeded' && patient) {
            // Find official name or default to first name
            const officialName = patient.name?.find(n => n.use === "official") || patient.name?.[0] || patient.name;

            const patientFirstName = officialName?.given?.join(" ") || "";
            const patientLastName = officialName?.family || "";
            return `Selected patient: ${patientFirstName} ${patientLastName} | Patient ID: ${patient.id}`;
        } else {
            return "No patient selected";
        }
    };

    return (
        <div className={"patient-callout__wrapper"}>
            <Callout
                text={patientInfoContent()}
                icon={
                    patientStatus === 'loading'
                        ? 'alert-circle'
                        : patientStatus === 'succeeded'
                            ? 'check-circle'
                            : 'x-circle-filled'
                }
                type={
                    patientStatus === 'loading'
                        ? 'warning'
                        : patientStatus === 'succeeded'
                            ? 'success'
                            : 'error'
                }
            />
        </div>
    );
}


export default PatientCallout
